<template>
    <div class="contactus__wrapper">
        <div class="page-header page-header-small clear-filter" filter-color="primary">
            <div class="page-header-image"></div>
            <div class="content-center">
                <div class="row">
                    <div class="col-md-10 ml-auto mr-auto">
                        <h1 class="title">Feel free to contact us</h1>
                        <h4>We know that new things can be scary and you might have a few questions for us. We really hope the below helps, but if not, please do not hesitate to contact us.</h4>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="container">
                <div class="row">
                    <div class="col-md-9 ml-auto mr-auto">
                        <fade-transition>
                            <div v-if="uiModel.sendResult.attempt == 0">
                                <form class="m-3" @submit.prevent="submitForm()">
                                    <!-- Category -->
                                    <div class="form-group">
                                        <label>Category</label>
                                        <select class="form-control" v-model="formModel.category">
                                            <option>General Enquiry</option>
                                            <option>Report a Fault</option>
                                            <option>Complaint</option>
                                        </select>
                                    </div>

                                    <!-- First Name -->
                                    <fg-input 
                                        v-model="formModel.firstName" 
                                        :class="{ 'has-danger' : !formModel.validationStatus.firstName.isValid }" 
                                        :error="formModel.validationStatus.firstName.message"
                                        v-on:blur="formModel.validateFirstName()"
                                        label="First name"
                                        placeholder="Enter your name..." 
                                        type="text">
                                    </fg-input>

                                    <!-- Last Name -->
                                    <fg-input 
                                        v-model="formModel.lastName" 
                                        :class="{ 'has-danger' : !formModel.validationStatus.lastName.isValid }"
                                        :error="formModel.validationStatus.lastName.message" 
                                        v-on:blur="formModel.validateLastName()"
                                        label="Last name" 
                                        placeholder="Enter your last name..." 
                                        type="text">
                                    </fg-input>

                                    <!-- Email Address -->
                                    <fg-input 
                                        v-model="formModel.emailAddress" 
                                        :class="{ 'has-danger' : !formModel.validationStatus.email.isValid }" 
                                        :error="formModel.validationStatus.email.message"
                                        v-on:blur="formModel.validateEmail()"
                                        label="Email address"
                                        placeholder="Enter your email address..."
                                        type="email">
                                    </fg-input>

                                    <!-- Message -->
                                    <div class="form-group" :class="{ 'has-danger' : !formModel.validationStatus.message.isValid }">
                                        <label>Your message</label>
                                        <textarea 
                                            v-model="formModel.message" 
                                            v-on:blur="formModel.validateMessage()" 
                                            class="form-control" 
                                            rows="6"
                                            placeholder="You can add your message here...">
                                        </textarea>
                                        <div class="text-danger invalid-feedback" :class="{ 'd-block' : !formModel.validationStatus.message.isValid }">{{ formModel.validationStatus.message.message }}</div>
                                    </div>

                                    <div class="d-flex flex-row justify-content-center">
                                        <vue-recaptcha 
                                            ref="recaptcha"
                                            :sitekey="recaptchaModel.siteKey"
                                            @verify="validateRecaptcha">
                                        </vue-recaptcha>
                                    </div>

                                    <!-- Buttons -->
                                    <div class="from-button-row d-flex flex-row justify-content-center">
                                        <button type="submit" class="btn btn-primary" outline="" v-if="!uiModel.isSending" :disabled="recaptchaModel.isRobot">
                                            SEND <i class="fa fa-paper-plane"></i>
                                        </button>

                                        <loading-indicator v-if="uiModel.isSending"></loading-indicator>
                                    </div>
                                </form>
                            </div>
                            <div v-else-if="uiModel.sendResult.success && uiModel.sendResult.attempt > 0">
                                <alert type="success">Your email has been sent successfully. We will be in contact shortly <i class="fa fa-smile-beam"></i></alert>
                            </div>
                            <div v-else-if="!uiModel.sendResult.success && uiModel.sendResult.attempt > 0">
                                <alert type="danger">Something went wrong while trying to send your email <i class="fa fa-sad-tear"></i> We are so sorry for the inconvenience. Please try again later</alert>
                            </div>
                        </fade-transition>
                    </div>
                    <div class="col-md-3 ml-auto mr-auto">
                        <div class="info info-horizontal d-flex justify-content-center">
                            <div class="icon icon-primary">
                                <i class="fa fa-home"></i>
                            </div>
                            <div class="description flex-fill text-center">
                                <h4 class="info-title">Operating Hours</h4>
                                <p>Mon - Fri, 8:00-17:00</p>
                            </div>
                        </div>

                        <div class="info info-horizontal d-flex justify-content-center">
                            <div class="icon icon-primary">
                                <i class="fa fa-envelope"></i>
                            </div>
                            <div class="description flex-fill text-center">
                                <h4 class="info-title">Pop us an email</h4>
                                <p>drremo@health263.systems</p>
                            </div>
                        </div>

                        <div class="info info-horizontal d-flex justify-content-center">
                            <div class="icon icon-primary">
                                <i class="fas fa-sad-tear"></i>
                            </div>
                            <div class="description flex-fill text-center">
                                <h4 class="info-title">Have a complaint?</h4>
                                <p>drremo@health263.systems</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { FormGroupInput as FgInput } from '@/components';
import { Alert } from '@/components';
import { FadeTransition } from 'vue2-transitions';

import VueRecaptcha from 'vue-recaptcha';
import ContactUsService from '@/services/contactUsService';

export default {
  name: 'contactus',
  bodyClass: 'contactus-page',
  components: {
    FgInput,
    Alert,
    FadeTransition,
    VueRecaptcha
  },
  data() {
    return {
        recaptchaModel: {
            siteKey: `${process.env.VUE_APP_RECAPTCHASITEKEY}`,
            isRobot: true
        },
        uiModel: {
            isSending: false,
            sendResult: {
                attempt: 0,
                success: false
            }
        },
        formModel: {
            emailAddress: "",
            firstName: "",
            lastName: "",
            message: "",
            category: "General Enquiry",

            validationStatus: {
                email: {
                    isValid: true,
                    message: ""
                },
                firstName: {
                    isValid: true,
                    message: ""
                },
                lastName: {
                    isValid: true,
                    message: ""
                },
                message: {
                    isValid: true,
                    message: ""
                }
            },

            toJsonModel() {
                var self = this;
                return {
                    fromAddress: self.emailAddress,
                    firstName: self.firstName,
                    lastName: self.lastName,
                    message: self.message,
                    category: self.category
                };
            },
            validateEmail() {
                var self = this;
                var emailRegex = RegExp(/^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/);
                
                self.validationStatus.email.isValid = emailRegex.test(self.emailAddress);
                self.validationStatus.email.message = self.validationStatus.email.isValid ? null : "Email must be specified in the correct format";

                return self.validationStatus.email.isValid;
            },
            validateFirstName() {
                var self = this;
                var nameRegex = RegExp(/^[A-Za-z\s\-]*$/);
                
                self.validationStatus.firstName.isValid = nameRegex.test(self.firstName);
                self.validationStatus.firstName.message = self.validationStatus.firstName.isValid ? null : "First name is required";

                return self.validationStatus.firstName.isValid;
            },
            validateLastName() {
                var self = this;
                 var nameRegex = RegExp(/^[A-Za-z\s\-]*$/);
                
                self.validationStatus.lastName.isValid = nameRegex.test(self.lastName);
                self.validationStatus.lastName.message = self.validationStatus.lastName.isValid ? null : "Last name is required";

                return self.validationStatus.lastName.isValid;
            },
            validateMessage() {
                var self = this;
                var messageRegex = RegExp(/^[\s\S]{5,}$/);
                
                self.validationStatus.message.isValid = messageRegex.test(self.message);
                self.validationStatus.message.message = self.validationStatus.message.isValid ? null : "A message is required";

                return self.validationStatus.message.isValid;
            }
        }
    };
  },
  methods: {
    validateRecaptcha(response) {
        var self = this;
        if(response) {
            self.recaptchaModel.isRobot = false;
            return;
        }

        self.recaptchaModel.isRobot = true;
    },
    validateForm() {
        var self = this;
        var validEmail = self.formModel.validateEmail();
        var validFirstName = self.formModel.validateFirstName();
        var validLastName = self.formModel.validateLastName();
        var validMessage = self.formModel.validateMessage();

        return validEmail && validFirstName && validLastName && validMessage;
    },
    submitForm() {
        var self = this;
        if(self.validateForm()) {
            var contactUs = new ContactUsService();

            self.uiModel.isSending = true;
            contactUs.sendEmail(self.formModel.toJsonModel(), self.handleSendEmailResponse);
        }
    },

    handleSendEmailResponse(response) {
        var self = this;
        self.uiModel.sendResult.isSending = false;
        self.uiModel.sendResult.attempt += 1;

        if(response.status === 200) {
            self.uiModel.sendResult.success = true;
        } else {
            self.uiModel.sendResult.success = false;
        }
    }
  },
    metaInfo() {
        return {
            title: "DrRemo | Contact Us",
            meta: [
                {
                    name: "description",
                    content: "Get in touch with our team, we look forward to hearing from you."
                },
                {
                    name: "robots",
                    content: "index, follow"
                }
            ],
            link: [
                {
                rel: "canonical",
                href: "https://dr-remo.co.za/contactus"
                }
            ]
        }
    }
};
</script>