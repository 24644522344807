import axios from 'axios'

export default class ContactUsService {
    sendEmail(contactUsModel, handleResponse) {
        var endPoint = `${process.env.VUE_APP_GATEWAYENDPOINT}/communication/communication/send/email/contactus`;
        axios
            .post(endPoint, contactUsModel)
            .then(response => handleResponse(response))
            .catch(error => {
                handleResponse(error);
            });
    }
}